import React from 'react';

export default function Footer(){
    return(
        <div className="footer-container">
            <h6 className="footer-text">
                Marcin Angielczyk.
            </h6>
        </div>
    )
}